import 'bootstrap';
import 'summernote/dist/summernote';

export function setSummernote($container = null)
{
    if ($container == null) {
        $container = $('body');
    }

    $container.find('.summernote').each(function()
    {
        $(this).summernote({
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['strikethrough', 'superscript', 'subscript']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['height', ['height']],
                ['insert', ['link']],
                ['misc', ['codeview']]
            ],
            callbacks: {
                onPaste: function (e) {
                    var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                    e.preventDefault();
                    setTimeout(function(){
                        document.execCommand( 'insertText', false, bufferText );
                    }, 10);
                }
            }
        });
    });
}