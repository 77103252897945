import { Controller } from '@hotwired/stimulus';
import {enableInput, disableInput} from "../../../../lib/input.config";

export default class extends Controller {
    connect() {
        var $typeIds = $('#data-js').data('type-ids');
        var $inputTheType = $('.input-the-type');
        var $inputTheEntreprise = $('.input-the-entreprise');

        function setInputTheType()
        {
            if ($inputTheType.val() == $typeIds.interne) {
                disableInput($inputTheEntreprise);
            } else {
                enableInput($inputTheEntreprise);
            }
        }

        setInputTheType();

        $inputTheType.change(function ()
        {
            setInputTheType();
        });
    }
}
