import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        var evenementTypes = $('#data-js').data('evenement-types');

        var $inputTheType = $('.input-the-type');
        var $containerUrlInscription = $('#container-url-inscription');
        var $containerTheAdresse = $('#container-the-adresse');

        function setInputFromType()
        {
            if ($inputTheType.val() == evenementTypes['en-ligne']) {
                $containerTheAdresse.addClass('d-none');
                $containerUrlInscription.removeClass('d-none');
            } else {
                $containerUrlInscription.addClass('d-none');
                $containerTheAdresse.removeClass('d-none');
            }
        }

        setInputFromType();

        $inputTheType.change(function()
        {
            setInputFromType();
        });
    }
}