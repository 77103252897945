import * as select2Config from "./select2.config";
import * as imaskConfig from "./jquery.mask.config";
import * as fichierConfig from "./fichier.config";
import * as summernoteConfig from "./summernote.config";

export function setCollectionManage($container = null, options = {})
{
    if ($container == null) {
        $container = $('body');
    }

    var $collectionContainer = $container.find('.collection-container');

    $collectionContainer.each(function()
    {
        setCollection($(this), options);
    });
}

export function setCollection($currentCollectionContainer, options = {})
{
    var $btnAdd = $currentCollectionContainer.find('.collection-item-add');
    var $rowContainer = $currentCollectionContainer.find('.collection-item-container');
    var nbRow = $currentCollectionContainer.find('.collection-item').length;
    var dataRequired = $(this).data('required');
    var isNotEmpty = false;

    $currentCollectionContainer.data('index', nbRow);

    $currentCollectionContainer.on('click', '.collection-item-delete', function()
    {
        var $parentItem = $(this).parents('.collection-item');
        $parentItem.remove();
        if (options.hasOwnProperty('afterRemove')) {
            options.afterRemove();
        }
    });

    if ($btnAdd.length > 0) {
        $btnAdd.click(function()
        {
            addElement($currentCollectionContainer, $rowContainer, isNotEmpty);

            if (options.hasOwnProperty('afterAdd')) {
                options.afterAdd();
            }
        });
    }
}

export function addElement($currentCollectionContainer, $rowContainer, isNotEmpty = false)
{
    // Get the data-prototype explained earlier
    var prototype = $currentCollectionContainer.data('prototype');

    // get the new index
    var index = parseInt($currentCollectionContainer.attr('data-index'));

    var newForm = prototype;

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    newForm = newForm.replace(/__name__/g, index);

    // increase the index with one for the next item
    $currentCollectionContainer.attr('data-index', index + 1);

    // Display the form in the page in an li, before the "Add a tag" link li
    var $newForm = $(newForm);
    $rowContainer.append($newForm);

    select2Config.setSelect2($newForm);
    fichierConfig.setCustomFileInput($newForm);
    imaskConfig.setImask($newForm);
    summernoteConfig.setSummernote($newForm);

    if (isNotEmpty) {
        hideDeleteButton($rowContainer);
    }

    return $newForm;
}

function hideDeleteButton($parentItem)
{
    if ($parentItem.find('.collection-item').length === 1) {
        $parentItem.find('.collection-item-delete').addClass('d-none');
    } else {
        $parentItem.find('.collection-item-delete').removeClass('d-none');
    }
}