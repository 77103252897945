import { Controller } from '@hotwired/stimulus';
import Routing from '../../../../routing'
import * as fichierConfig from '../../../../lib/fichier.config'

export default class extends Controller {
    connect() {
        var $dataJs =  $('#data-js');
        var currentGroupe = $dataJs.data('current-groupe');
        var currentEntity = $dataJs.data('current-entity');

        function showModalEntity()
        {
            fichierConfig.setCustomFileInput($('#modal-categorie-et-entity-form'));
            $('#modal-categorie-et-entity-form').modal('show');
        }

        $('#btn-add-entity').click(function()
        {
            var url = Routing.generate('configuration_categorie_et_type_add', {'groupe' : currentGroupe, 'entity' : currentEntity});

            $('#modal-ajax-container').load(url, function()
            {
                showModalEntity();
            });
        });

        $('.btn-edit-entity').click(function()
        {
            var id = $(this).parents('.entity-item').data('id');
            var url = Routing.generate('configuration_categorie_et_type_edit', {'groupe' : currentGroupe, 'entity' : currentEntity, 'id' : id});

            $('#modal-ajax-container').load(url, function()
            {
                showModalEntity();
            });
        });
    }
}