import { Controller } from '@hotwired/stimulus';
import Routing from '../../../../routing'
import 'select2'
import * as collection from '../../../../lib/collection.config'

export default class extends Controller {
    connect() {
        var url = Routing.generate('commun_insee_insee_api_search');

        $('.input-entreprise-search-insee').select2({
            placeholder: 'Saisir une Entreprise...',
            ajax: {
                url: url,
                delay: 1000, // wait 250 milliseconds before triggering the request
                data: function (params) {
                    return {
                        search: params.term,
                        type: 'public'
                    };
                }
            }
        });

        $('.input-entreprise-search-insee').on('select2:select', function(event)
        {
            var data = event.params.data;
            $('.input-raison-sociale').val(data['data']['uniteLegale']['denominationUniteLegale']);
            $('.input-code-ape').val(data['data']['uniteLegale']['activitePrincipaleUniteLegale']);
            $('.input-siret').val(data['data']['siret']);
            $('.input-sigle').val(data['data']['uniteLegale']['sigleUniteLegale']);
            $('#list-adresse-container .collection-item').remove();
            var $element = collection.addElement(
                $('#list-adresse-container .collection-container'),
                $('#list-adresse-container .collection-item-container')
            );
            $element.find('.input-numero-voie').val(data['data']['adresseEtablissement']['numeroVoieEtablissement']);
            $element.find('.input-libelle-voie').val(data['data']['adresseEtablissement']['libelleVoieEtablissement']);
            $element.find('.input-code-postal').val(data['data']['adresseEtablissement']['codePostalEtablissement']);
            $element.find('.input-libelle-commune').val(data['data']['adresseEtablissement']['libelleCommuneEtablissement']);
            $element.find('.input-complement-adresse').val(data['data']['adresseEtablissement']['complementAdresseEtablissement']);
            $element.find('.input-code-cedex').val(data['data']['adresseEtablissement']['codeCedexEtablissement']);
            $element.find('.input-libelle-cedex').val(data['data']['adresseEtablissement']['libelleCedexEtablissement']);

            var typeVoieEtablissement = data['data']['adresseEtablissement']['typeVoieEtablissement']
            var $option = $element.find('.input-type-voie option[data-abreviation='+typeVoieEtablissement+']');
            if ($option.length > 0) {
                $('.input-type-voie').val($option.val());
                $('.input-type-voie').trigger('change');
            }

            $(this).val(null);
            $(this).trigger('change');
        });

    }
}