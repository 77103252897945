import "inputmask/dist/jquery.inputmask"

export function setImask($container = null)
{
    if ($container == null) {
        $container = $('body');
    }

    $container.find('.imask-num-telephone').each(function()
    {
        $(this).inputmask('+33999999999');
    });
}