import 'toastr'

export function toastrFlashbag()
{
    toastr.options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-bottom-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "1000",
        "hideDuration": "1000",
        "timeOut": "10000",
        "extendedTimeOut": "10000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    var typeToastr = ["success", 'error', 'info', 'warning'];

    var jsonFlashbag = $('#data-js').data('flashbag');

    $.each(jsonFlashbag, function(type, messages)
    {
        if ($.inArray(type, typeToastr) >= 0) {
            $.each(messages, function (key, message) {
                switch(type) {
                    case 'success':
                        toastr.success(message);
                        break;
                    case 'error':
                        toastr.error(message);
                        break;
                    case 'info':
                        toastr.info(message);
                        break;
                    case 'warning':
                        toastr.warning(message);
                        break;
                    default:
                        toastr.success(message);
                        break;
                }
            });
        } else {
            console.log('Type ' + type + ' non pris en charge par Toastr.');
        }
    });
}