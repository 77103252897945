import 'select2'

// ROUTING
var Routing = require('../routing');

// Thème boostrap select2
$.fn.select2.defaults.set("theme", "bootstrap4");

export function setSelect2($container = null)
{
    if ($container == null) {
        $container = $('body');
    }

    $container.find('.input-select2').each(function()
    {
        // Si on a une valeur null, on la passe en "placeholder" pour la librairie SELECT2
        var $optionPlaceholder = $(this).find('option[value=""]');
        var isAllowClear = true;
        var placeholder = false;
        var ajax = null;

        if ($optionPlaceholder.length > 0) {
            placeholder = $optionPlaceholder.html();
        }

        if ($(this).is(':required')) {
            isAllowClear = false;
        }

        if ($(this).hasClass('input-select2-ajax')) {
            var urlResult = null;
            var dataEntity = $(this).data('select2-entity');
            var dataDisableInit = $(this).data('select2-disable-init');

            switch (dataEntity) {
                case 'entreprise-entreprise':
                    if ($(this).data('select2-type') !== '') {
                        urlResult = Routing.generate(
                            'commun_entreprise_entreprise_select_ajax',
                            {
                                'typeId' : $(this).data('select2-type')
                            }
                        );
                    } else {
                        urlResult = Routing.generate('commun_entreprise_entreprise_select_ajax');
                    }
                    break;
                case 'user-user-membre':
                    urlResult = Routing.generate('commun_user_user_membre_select_ajax');
                    break;
                case 'user-user-admin':
                    urlResult = Routing.generate('commun_user_user_admin_select_ajax');
                    break;
                default:
                    console.log('Select2 ajax du type "' + dataEntity + '" inconnu.');
                    break;
            }

            if (urlResult != null) {
                ajax = {
                    url: urlResult,
                    delay: 1000, // wait 250 milliseconds before triggering the request
                    data: function (params) {
                        return {
                            search: params.term,
                            type: 'public'
                        };
                    }
                };
            }
        }

        $(this).select2({
            allowClear: isAllowClear,
            placeholder: placeholder,
            ajax: ajax
        });
    });
}
