export function setFichierDelete($container = null)
{
    if ($container == null) {
        $container = $('body');
    }

    $container.find('.fichier-delete-btn').each(function()
    {
        $(this).click(function()
        {
            var $inputFichier = $(this).parents('.fichier-container').find('.input-fichier');
            var $inputDelete = $(this).parents('.fichier-container').find('.input-delete');
            if ($inputDelete.val() === '') {
                $inputDelete.val(true);
                $(this).addClass('is-delete');
                $inputFichier.attr('disabled', true).addClass('disabled');
            } else {
                $inputDelete.val(null);
                $(this).removeClass('is-delete');
                $inputFichier.removeAttr('disabled').removeClass('disabled');
            }
        });
    });
}



// File input boostrap, changement des labels on change
export function setCustomFileInput($container = null)
{
    if ($container == null) {
        $container = $('body');
    }

    //Affiche les nom des fichier sur les input custom boostrap
    $container.find('.custom-file-input').change(function(e){
        var fileName = e.target.files[0].name;
        $(this).parents('.custom-file').find('.custom-file-label').html(fileName);
    });
}