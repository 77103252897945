import { Controller } from '@hotwired/stimulus';
import Routing from "../../../routing";

export default class extends Controller {
    connect() {
        /**
         * ETAPES FORMULAIRE
         */
        var $formInscription = $('#form-paiement');
        var $inscriptionStepContainer = $('#inscription-step-container');
        var currentStep = parseInt($inscriptionStepContainer.data('current-step'));
        var nbStep = parseInt($inscriptionStepContainer.data('nb-step'));

        function updateCurrentStep()
        {
            $inscriptionStepContainer.data('current-step', currentStep);
        }

        $('#inscription-step-previous').click(function()
        {
            if (currentStep - 1 >= 1) {
                $inscriptionStepContainer.find('#inscription-step-' + currentStep).removeClass('active');
                currentStep -= 1;
                $inscriptionStepContainer.find('#inscription-step-' + currentStep).addClass('active');
                updateCurrentStep();

                if (currentStep === 1) {
                    $('#inscription-step-previous').addClass('d-none');
                }
                $('#stripe-card-button').addClass('d-none');
                $('#inscription-step-next').removeClass('d-none');
            }
        });

        $('#inscription-step-next').click(function()
        {
            if (currentStep + 1 <= nbStep && $formInscription.valid()) {
                $inscriptionStepContainer.find('#inscription-step-' + currentStep).removeClass('active');
                currentStep += 1;
                $inscriptionStepContainer.find('#inscription-step-' + currentStep).addClass('active');
                updateCurrentStep();

                if (currentStep === nbStep) {
                    $('#inscription-step-next').addClass('d-none');
                    $('#stripe-card-button').removeClass('d-none');
                }
                $('#inscription-step-previous').removeClass('d-none');
            }
        });

        var $inputTheForfaitTarif = $('.input-the-forfait-tarif');

        function setPrixFromTheForfaitTarif()
        {
            $('#forfait-prix').html($inputTheForfaitTarif.find('option:selected').data('prix-ht'));
        }

        setPrixFromTheForfaitTarif();

        $inputTheForfaitTarif.change(function()
        {
            setPrixFromTheForfaitTarif();
        });

        /**
         * API SIRENE
         */
        var url = Routing.generate('commun_insee_insee_api_search');
        var $entrepriseSirenePrototype = $('#entreprise-sirene-prototype');
        var $entrepriseSireneContainer = $('#entreprise-sirene-container');

        $('#btn-entreprise-search').click(function()
        {
            $entrepriseSireneContainer.empty();
            $.getJSON(url, {'search' : $('.input-raison-sociale').val()}, function(json) {
                $.each(json.results, function(index, entreprise)
                {
                    var html = $entrepriseSirenePrototype.clone();
                    $(html).removeAttr('id');
                    $(html).find('.nom').html(entreprise['data']['uniteLegale']['denominationUniteLegale']);
                    $(html).find('.sigle').html(entreprise['data']['uniteLegale']['sigleUniteLegale']);
                    $(html).find('.siret').html(entreprise['data']['siret']);
                    $(html).find('.code-ape').html(entreprise['data']['uniteLegale']['activitePrincipaleUniteLegale']);
                    html.data('json', entreprise['data']);
                    var adresse =
                         entreprise['data']['adresseEtablissement']['numeroVoieEtablissement'] + ' ' +
                         entreprise['data']['adresseEtablissement']['typeVoieEtablissement'] + ' ' +
                         entreprise['data']['adresseEtablissement']['libelleVoieEtablissement'] + ' ' +
                         entreprise['data']['adresseEtablissement']['codePostalEtablissement'] + ' ' +
                         entreprise['data']['adresseEtablissement']['libelleCommuneEtablissement'];

                    $(html).find('.adresse').html(adresse);
                    $entrepriseSireneContainer.append(html);
                });
            });
        });

        $entrepriseSireneContainer.on('click', '.entreprise-sirene-select', function()
        {
            var $item = $(this).closest('.entreprise-sirene-item');
            var json = $item.data('json');
            var nom = $item.find('.nom').html();
            $('.input-raison-sociale').val(nom);
            $('#the-adresse-entreprise-container .input-numero-voie').val(json['adresseEtablissement']['numeroVoieEtablissement']);
            $('#the-adresse-entreprise-container .input-libelle-voie').val(json['adresseEtablissement']['libelleVoieEtablissement']);
            $('#the-adresse-entreprise-container .input-code-postal').val(json['adresseEtablissement']['codePostalEtablissement']);
            $('#the-adresse-entreprise-container .input-libelle-commune').val(json['adresseEtablissement']['libelleCommuneEtablissement']);
            $('#the-adresse-entreprise-container .input-pays').val('France');

            var typeVoieEtablissement = json['adresseEtablissement']['typeVoieEtablissement']
            var $option = $('#the-adresse-entreprise-container .input-type-voie option[data-abreviation='+typeVoieEtablissement+']');
            if ($option.length > 0) {
                $('#the-adresse-entreprise-container .input-type-voie').val($option.val());
                $('#the-adresse-entreprise-container .input-type-voie').trigger('change');
            }

            $('.input-code-ape').val(json['uniteLegale']['activitePrincipaleUniteLegale']);
            $('.input-siret').val(json['siret']);
            $('.input-sigle').val(json['uniteLegale']['sigleUniteLegale']);
            $entrepriseSireneContainer.empty();
        });

        if ($('#stripe-card-container').length > 0) {
            /**
             * COORDONNEES BANCAIRES STRIPE
             */
                // On instancie Stripe et on lui passe notre clé publique
            let stripe = Stripe($('#data-js').data('stripe-public-key'));

            // Initialise les éléments du formulaire
            let elements = stripe.elements();

            let form = document.getElementById('form-paiement');
            let loading = document.getElementById('stripe-card-loading');

            // $(form).validate({
            //     // Force la vérification des champs en hidden
            //     ignore: "",
            //     invalidHandler: function(event, validator){
            //         console.log(validator);
            //         toastr.error('Le formulaire n\'a pas pû être envoyé car il contient des erreurs. Veuillez vérifier tout les champs avant de l\'envoyer.');
            //     }
            // });

            // Définit la redirection en cas de succès du paiement
            let redirect = "/index.php";

            // Récupère l'élément qui contiendra le nom du titulaire de la carte
            let cardholderName = document.getElementById('paiement_nom');

            // Récupère l'élement button
            let cardButton = document.getElementById('stripe-card-button');

            // Crée les éléments de carte et les stocke dans la variable card
            let card = elements.create("card");


            // On récupère l'élément qui permet d'afficher les erreurs de saisie
            let displayError = document.getElementById('stripe-card-errors');

            card.mount("#stripe-card-container");

            function displayStripeError(error)
            {
                // Si il y a une erreur
                if (error) {
                    // On l'affiche
                    displayError.textContent = error.message;
                } else {
                    // Sinon on l'efface
                    displayError.textContent = '';
                }
            }

            card.addEventListener('change', function(event) {
                displayStripeError(event.error);
            });

            let isAlreadySend = false;
            cardButton.addEventListener('click', () => {
                if (!isAlreadySend && $(form).valid()) {
                    $(cardButton).addClass('d-none');
                    $(loading).removeClass('d-none');
                    isAlreadySend = true;

                    stripe
                        .createPaymentMethod({
                            type: 'card',
                            card: card,
                            billing_details: {
                                name: 'Test',
                            },
                        })
                        .then((result) => {
                            if (result.error) {
                                displayStripeError(result.error);
                                $(cardButton).removeClass('d-none');
                                $(loading).addClass('d-none');
                            } else {
                                console.log(result);
                                // Sinon on envoi le formulaire
                                $('.input-stripe-payment-method-id').val(result.paymentMethod.id);
                                if ($(form).valid()) {
                                    form.submit();
                                }
                            }
                        });
                }
            });
        } else {
            let form = document.getElementById('form-paiement');
            $('#stripe-card-button').click(function()
            {
                if($(form).valid()) {
                    form.submit();
                }
            });
        }
    }
}