var Routing = require('../routing');

$.validator.setDefaults({
    errorElement: 'span',
    errorPlacement: function (error, element) {
        error.addClass('invalid-feedback');
        element.closest('.form-group').append(error);
    },
    highlight: function (element, errorClass, validClass) {
        $(element).addClass('is-invalid');
    },
    unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass('is-invalid');
    }
});

/**
 * NOMBRE DECIMAL
 */
$.validator.addClassRules("validate-decimal", {
    number: true,
    min: 0
});

/**
 * NOMBRE ENTIER POSITIF
 */
$.validator.addClassRules("validate-integer-positive", {
    digits: true,
    min: 0
});

/**
 * NOMBRE ENTIER SUPERIEUR A ZERO
 */
$.validator.addClassRules("validate-integer-more-than-zero", {
    digits: true,
    min: 1
});

/**
 * UTILISATEURS
 */
var urlUserUniqueUsername = Routing.generate("commun_user_validate_unique", {"property" : "username"});
$.validator.addClassRules("validate-unique-user-username", {
    remote: {
        url : urlUserUniqueUsername,
        type: "post",
        data: {
            id_user: function() {
                return $( "#data-js" ).data("id-user");
            }
        }
}
});
var urlUserUniqueEmail = Routing.generate("commun_user_validate_unique", {"property" : "email"});
$.validator.addClassRules("validate-unique-user-email", {
    email: true,
    remote: {
        url : urlUserUniqueEmail,
        type: "post",
        data: {
            id_user: function() {
                return $( "#data-js" ).data("id-user");
            }
        }
    }
});

/**
 * PAGES
 */
var urlPageUniqueSlug = Routing.generate("commun_page_validate_unique", {"property" : "slug"});
$.validator.addClassRules("validate-unique-page-slug", {
    remote: {
        url : urlPageUniqueSlug,
        type: "post",
        data: {
            id_page: function() {
                return $( "#data-js" ).data("id-page");
            }
        }
}
});

/**
 * SITE WEB
 */
$.validator.addMethod("url-site-web", function(value, element, param) {
    var regex = new RegExp('(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,}|^\\/\\w+(\\.\\w+)*(:[0-9]+)?(\\/.*)?$)')
    return this.optional(element) || regex.test(value);
}, $.validator.format("Veuillez saisir une adresse web valide (https://www.google.com)."));

$.validator.addClassRules("validate-url-site-web", {
    'url-site-web': true,
});

/**
 * NUMERO DE TELEPHONE
 */
$.validator.addMethod("num-telephone", function(value, element, param) {
    var regex = new RegExp('[+][3][3][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]')
    return this.optional(element) || regex.test(value);
}, $.validator.format("Veuillez saisir une numéro de téléphone au format valide (+33101010101)."));

$.validator.addClassRules("validate-num-telephone", {
    'num-telephone': true,
});

/**
 * EMAIL
 */
$.validator.addMethod(
    "email-cc",
    function(value, element)
    {
        var isValid = true;
        if (value !== "") {
            var arrayEmail = value.split(',');
            var regexEmail = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            for(var index in arrayEmail) {
                if (!regexEmail.test(arrayEmail[index])) {
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    },
    "Veuillez saisir des adresses mails valides."
);

jQuery.validator.addClassRules("validate-email-cc", {
    "email-cc": true,
});

/**
 * IMPORT
 */
jQuery.validator.addClassRules("validate-file-import", {
    "accept": "text/csv",
});

/**
 * REPEATED
 */
$.validator.addMethod(
    'repeated-second',
    function(value, element)
    {
        var idFirst = $(element).data('repeated-id');
        var $first = $('.validate-repeated-first[data-repeated-id=' + idFirst + ']');
        var firstValue = $first.val();

        return value === firstValue;
    },
    "Les valeurs saisies ne sont pas identiques."
);
$.validator.addClassRules("validate-repeated-second", {
    'repeated-second': true,
});


$.validator.addMethod(
    'date-debut',
    function(value, element)
    {
        var selector = $(element).data('target-date-fin');
        var dateDebut = new Date(value);
        var dateFin = new Date($(selector).val());

        return $(selector).val() === '' || dateDebut <= dateFin;
    },
    "La date de début doit être inférieure ou égale à la date de fin."
);
$.validator.addClassRules("validate-date-debut", {
    'date-debut': true,
});

$.validator.addMethod(
    'date-fin',
    function(value, element)
    {
        var selector = $(element).data('target-date-debut');
        var dateFin = new Date(value);
        var dateDebut = new Date($(selector).val());

        return $(selector).val() === '' || dateFin >= dateDebut;
    },
    "La date de fin doit être supérieure ou égale à la date de début."
);
$.validator.addClassRules("validate-date-fin", {
    'date-fin': true,
});