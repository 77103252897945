import { Controller } from '@hotwired/stimulus';

import Routing from '../../../../routing';

function setSelectAjax()
{
    var url = Routing.generate('commun_user_user_membre_select_ajax', {'entrepriseId' : $('.input-the-entreprise').val()});
    $('.input-the-user-membre').select2({
        ajax: {
            url: url
        }
    });
}

export default class extends Controller {
    connect() {
        setSelectAjax();

        $('.input-the-entreprise').change(function()
        {
            $('.input-the-user-membre').val(null);
            setSelectAjax();
        });
    }
}