export function enableInput($input, isRequired = true)
{
    $input.removeAttr('disabled').removeClass('disabled').attr('required', true).removeClass('required');
    if (isRequired) {
        var $formGroup = $input.closest('.form-group');
        var $label = $formGroup.find('label');
        $label.addClass('required');
    }
}

export function disableInput($input, isRequired = true)
{
    $input.attr('disabled', true).addClass('disabled').removeAttr('required').removeClass('required');
    if (isRequired) {
        var $formGroup = $input.closest('.form-group');
        var $label = $formGroup.find('label');
        $label.removeClass('required');
    }
}