import 'sortablejs';
import { Controller } from '@hotwired/stimulus';
import {setCollection} from "../../../../lib/collection.config";

export default class extends Controller {
    connect() {
        function updateIndexOrdre()
        {
            var indexOrdre = 0;
            $('#list-media-container .list-media-item').each(function()
            {
                $(this).find('.input-index-ordre').val(indexOrdre);
                ++indexOrdre;
            });
        }

        var listMediaContainer = document.getElementById('list-media-item-container');

        new Sortable(listMediaContainer, {
            animation: 150,
            ghostClass: 'bg-drag',
            onSort: function(evt)
            {
                updateIndexOrdre();
            }
        });

        updateIndexOrdre();

        $("#list-media-ordre").click(function()
        {
            updateIndexOrdre();
        });

        setCollection($('#list-media-container'), {
            'afterAdd' : function()
            {
                updateIndexOrdre();
            },
            'afterRemove' : function()
            {
                updateIndexOrdre();
            },
        });
    }
}
